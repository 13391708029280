<div *ngIf="isDesktop && isAccessGranted(); else Authenticating" class="main {{nativeClass}}"
  [ngClass]="{ 'collapsed':isCollapsed }">
  <div class="sidebar" *ngIf="sidebar">
    <div class="main-menu">
      <app-sidebar [navigationUrl]="activeMenuItem" [loginUserDetail]="loginUserDetail" (collapsed)="collapsed($event)"
        [isSkelton]="isSkelton">
      </app-sidebar>
    </div>
  </div>
  <div class="container desktop DataBodyContaner"
    [ngClass]="[(device == 'ios' && isNative)? 'ios-device' : '' || (device == 'android') ? 'android-device' : 'web' && (sidebar === false) ? 'no-sidebar' : '']">
    <router-outlet></router-outlet>
  </div>
</div>
<ng-template #Authenticating>
  <div class="loading-container">
    <div class="loading-message">
      <h2 class="status">Authenticating...</h2>
    </div>
  </div>
</ng-template>
<ion-app *ngIf="!isDesktop" class="main {{nativeClass}}">
  <ion-split-pane when="md" contentId="sidebar">
    <ion-menu swipe-gesture="false" contentId="sidebar" side="start" [type]="menuType">
      <span class="close-icon" (click) = "closeMenu()"><snl-icon iconType="svg" icon="icon/close-large"></snl-icon></span>
      <app-sidebar 
       [navigationUrl] ="activeMenuItem" 
       [loginUserDetail] ="loginUserDetail" 
       (collapsed)="collapsed($event)"
       [isSkelton] = "isSkelton">
      </app-sidebar>
    </ion-menu>
    <div id="sidebar"  class="container DataBodyContaner" [ngClass]="[(device == 'ios' && isNative)? 'ios-device' : '' || (device == 'android') ? 'android-device' : 'web']">
      <app-header [title]="pageTitle" *ngIf="sidebar"></app-header>
        <ion-tabs class="bootom-menu">
          <ion-tab-bar *ngIf="sidebar" class="bottom-navigation" [ngClass]="{'ios-margin-bottom':device == 'ios'}" slot="bottom">
            <ion-tab-button *ngFor="let menuItem of bottomMenuOptions" [tab]="menuItem.path" [routerLink]="menuItem.path">
              <snl-icon class="df-c" [ngClass]="{ 'hide':menuItem.selected}" [iconType]="menuItem.iconType" [icon]="menuItem.iconName"></snl-icon>
              <snl-icon class="df-c" [ngClass]="{ 'hide':!menuItem.selected}" [iconType]="menuItem.iconType" icon="{{menuItem.iconName}}-active"></snl-icon>
              <ion-label class="caption color__neutral" [ngClass]="{ 'payroll':menuItem.path == 'payroll-expenses'}">{{menuItem.displayName}}</ion-label>
            </ion-tab-button> 
          </ion-tab-bar>
      </ion-tabs>
    </div>
  </ion-split-pane>
</ion-app>
  
  