
<ng-container>
  <div class="header d-flex justify-content-between">
    <div class="header__left">
      <span class="sub-heading-s2 fw-700 color__primary">{{title}}</span>   
    </div>
    <div class="header__right">
      <ion-buttons slot="start" class="df-c">
        <snl-icon class="cursor-pointer" icon="fa fa-close" (click)="dismissModal('cancel')"></snl-icon>
      </ion-buttons>
    </div>
  </div>
  <div class="wrapper">
    <div class="content">
      <span class="body-b1 color__primary"></span>
      <div class="body-b1 color__primary" [innerHTML]="message"></div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions d-flex gap-16">
      <snl-button [type]="'primary'" [size]="'large'" [disabled]="submit" (buttonClick)="success()">{{btnOneLabel}}</snl-button>
      <snl-button [type]="'secondary'" [size]="'large'" [disabled]="submit" (buttonClick)="dismissModal('cancel')">{{btnTwoLabel}}</snl-button>
    </div>
  </div>
</ng-container>

