<div class="modal-wrapper">
  <div class="header">
    <div class="heading"><span class="icon" (click)="dismissModal('close')"> <snl-icon iconType="svg" icon="icon/chevron-left"></snl-icon> </span><span class="title sub-heading-s1">{{label}}</span></div>
  </div>
  <div class="dropdown-content">
    <div class="search-bar">
      <snl-input class="search-input" [label]="'Search'" (onClearField)="onClearField($event)"  (input)="onSearch($event)" [isTrailingIconDisplayed]="true"
        trailingIcon="fa-magnifying-glass fa-solid">
      </snl-input>
    </div>
    <ion-list lines="none" class="inner-content">
      <ion-list-header class="section-title fw-400" *ngIf="!filterText">
        <span >All ({{ filterOptionData().length }})</span>
      </ion-list-header>
      <ion-radio-group value="custom-checked" [value] ="preSelectedItems">
        <div class="list" *ngFor="let item of filterOptionData()" (click)="selectAttribute({id:item.id, name:item.name})">
          <ion-radio slot="start" [value]="item.id" aria-label="Custom checkbox"></ion-radio>
          <ion-label>
            <span class="body-b1 color__primary" [innerHTML]="item.name | bold:filterText"></span>
          </ion-label>
        </div>
      </ion-radio-group>
    </ion-list>
  </div>
  <ion-footer class="button">
    <ion-col><snl-button [type]="'primary'" [size]="'large'" [disabled]="disabled()" (buttonClick)="apply('apply')">{{buttonPrimary}}</snl-button></ion-col>
    <ion-col><snl-button [type]="'secondary'" [size]="'large'" (buttonClick)="resetAttribute()">{{buttonSecondary}}</snl-button></ion-col>
  </ion-footer>
</div>