<snl-dropdown *ngIf="isDesktop" [ngClass]="{ 'error':isError && prevValue !== undefined }"
    [preSelectedItems] = "preSelect" 
    [label]="label"
    [buttonPrimary]="buttonPrimary"
    [buttonSecondary]="buttonSecondary"
    [options]="options"
    [multiple]="false" 
    (selectedValues)="selectedValuesChange($event)"
    (resetDropdown) ="resetDropdownChange($event)"
    [showAll]="true"
    [isFiltered]="false"
></snl-dropdown>
<form autocomplete="off"  [formGroup]="dropdownForm" *ngIf="reset && !isDesktop">
    <div class = 'dropdown-input' [ngClass]="{'error': isError  && prevValue !== undefined}">
        <div class="input">
            <snl-input class="dropdown-input" [isError] = "isError && prevValue !== undefined"  (click) = "onDropdownClick()" [label]="label" [type]="'text'" formControlName="dropdownField" [value]="dropdownForm.value.dropdownField"></snl-input>
        </div>
        <div class="trailingIcon">
            <snl-icon icon="fa-solid fa-chevron-down"></snl-icon>
        </div>
  </div>
    
</form>
