import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'stolt-textarea',
  templateUrl: './textarea.component.html',
  styleUrl: './textarea.component.scss',
  encapsulation: ViewEncapsulation.None,

})
export class TextAreaComponent {

  @Output() fieldValue = new EventEmitter();
  @Input() label: any = 'label';
  @Input() value: any = '';
  @Input() maxLength: number = 0;
  @Input() isError: boolean = false;
  
  textAreaValue: string = '';
  inputType:boolean = false;
  prevValue:any;

    ngOnChanges(changes: SimpleChanges) {
      for (let change in changes) {
         this.prevValue = changes['value']?.previousValue;
        if(change == "value" && changes['value'].currentValue){
          this.textAreaValue = changes['value'].currentValue;
          this.inputType = true;
        }
      } 
   }

  input(event: Event): void {
    this.inputType = true;
    const input = event.target as HTMLInputElement;
    this.textAreaValue = input.value;
    this.fieldValue.emit(this.textAreaValue);
  }

}
