import { Component, OnInit, Renderer2 } from '@angular/core';
import { CrewSelfService } from '../shared/services/crew-self.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { StoltApiService } from '../shared/services/stolt-api.service';
import { DocumentsPdfDetails } from '../shared/interfaces/stolt.interface';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {

  public isDesktop = false;
  public isPublic: boolean = false;
  public subscription: Subscription;
  public isFullWidth: boolean = false;
  public title: string = 'Privacy policy applications - Crew Self Service';
  policySections: { title: string; content: SafeHtml }[] = [];

  constructor(private readonly route: ActivatedRoute,
    private readonly crewSelfService: CrewSelfService,
    private readonly stoltApiService: StoltApiService,
    private readonly router: Router,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2
  ) {
    this.getRouter();
    this.subscription = this.crewSelfService.isDesktopGet().subscribe((event) => {
      this.isDesktop = event;
    })
    this.policySections = [
      {
        title: 'Introduction',
        content: this.sanitizer.bypassSecurityTrustHtml(
          `As an employee in a subsidiary of Stolt-Nielsen Ltd. (Stolt-Nielsen) you are protected by our privacy policy. Stolt-Nielsen has issued an Employee Privacy Notice (“Privacy Notice”), and the regulations in this notice apply when Personal Data (data) are exchanged via Crew Self Service. All users of Crew Self Service are encouraged to read the <a class="text-decoration cursor-pointer color__primary_default" id="employee-privacy-notice">Employee Privacy Notice</a>. In addition to the Privacy Notice, also Stolt-Nielsen’s general Data Protection Policy applies <a class="text-decoration cursor-pointer color__primary_default" id="data-protection-policy">Data Protection Policy</a>.<br/><br/>
          The following is a summary of the Privacy Notice referred to above and certain aspects with relevance for the Crew Self Service is accentuated.`
        ),
      },
      {
        title: 'The purpose of the Privacy Notice',
        content: this.sanitizer.bypassSecurityTrustHtml(
          `The purpose of the Privacy Notice is to protect your data and your personal rights as a data subject according to applicable data protection laws (Privacy Notice item 1.0).`
        ),
      },
      {
        title: 'The data we collect',
        content: this.sanitizer.bypassSecurityTrustHtml(
          `The data we collect are described in the Privacy Notice item 2.0. It is only data relevant for your employment we collect. When using Crew Self Service data concerning the digital communication will be also collected such as device used, model, language, time zone, country, app version, location, ipaddress, cellular carrier and application identifier.`
        ),
      },
      {
        title: 'The basis for collecting and processing your data',
        content: this.sanitizer.bypassSecurityTrustHtml(
          `The legal basis for collecting your data is the employment and processing of your data is necessary to administrate the workforce. It is also necessary to process your data to comply with legal obligations attributable to the business (Privacy Notice item 3.0).`
        ),
      },
      {
        title: 'Withholding or withdrawing of consent',
        content: this.sanitizer.bypassSecurityTrustHtml(
          `You are free to withdraw your consent for us processes your data and to delete the Crew Self Service at any time (Privacy Notice item 4.0).`
        ),
      },
      {
        title: 'Data anonymisation',
        content: this.sanitizer.bypassSecurityTrustHtml(
          `Your data may be converted and anonymised for use for analytical analyses and statistical research (Privacy Notice item 5.0).`
        ),
      },
      {
        title: 'Sharing of data',
        content: this.sanitizer.bypassSecurityTrustHtml(
          `Your data will only be shared with third parties when legitimate and your data will be under the same legal protection (Privacy Notice item 6.0).`
        ),
      },
      {
        title: 'Transfer of data in/out of the EU/EEA',
        content: this.sanitizer.bypassSecurityTrustHtml(
          `We will transfer and process your data inside and outside the EU/EEA and according to applicable law (Privacy Notice item 7.0).`
        ),
      },
      {
        title: 'Data Retention',
        content: this.sanitizer.bypassSecurityTrustHtml(
          `Your data will not be retained longer than the law permits (Privacy Notice item 8.0).`
        ),
      },
      {
        title: 'Digital rights',
        content: this.sanitizer.bypassSecurityTrustHtml(
          `We recognise your individual rights as employee and data subject (Privacy Notice, item 9.0). In short, you have the right to be informed that data is collected, right to access the data collected, right to correct the data if inaccurate or incomplete, right to restrict data being transferred, right to object to further processing of your data and the right to have your data deleted.`
        ),
      },
    ];
  }


  ngAfterViewInit() {
    const dataProtectionLink = document.getElementById('data-protection-policy');
    if (dataProtectionLink) {
      this.renderer.listen(dataProtectionLink, 'click', (event) => {
        event.preventDefault();
        this.getPolicyDocument('DataProtectionPolicy');
      });
    }
    const employeePrivacyLink = document.getElementById('employee-privacy-notice');
    if (employeePrivacyLink) {
      this.renderer.listen(employeePrivacyLink, 'click', (event) => {
        event.preventDefault();
        this.getPolicyDocument('EmployeePrivacyNotice');
      });
    }
  }

  getRouter() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.parseRoute(this.router.routerState.snapshot.root);
      }
    })
  }
  private parseRoute(node: ActivatedRouteSnapshot) {
    node.children.forEach(child => {
      this.isFullWidth = (child.data['width'] == 'full') ? true : false;
    });
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.isPublic = !data['sidebar'];
    });
  }
  getPolicyDocument(docType: string) {
    this.stoltApiService.loadPolicyDocument(`load-policy-document?type=${docType}`)
    .subscribe({
    next: (response: DocumentsPdfDetails) => {
        window.open(response?.data?.fileUrl, "_blank");
      },
      error: (err: any) => {
        console.error('Error fetching documents pdf content:', err);
      }
    })
  }
}
