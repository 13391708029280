import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SnlComponentsModule } from 'snl-complib';
import { provideHttpClient, withInterceptorsFromDi, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './auth/_guards';
import { DatePipe } from '@angular/common';
import { AuthInterceptor } from './shared/services/auth.interceptor';

import { HeaderComponent } from './shared/components/header/header.component';
import { SharedModuleModule } from '../app/shared/shared-module.module';
import { SidebarComponent } from '../app/shared/components/sidebar/sidebar.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    SharedModuleModule,
    IonicModule.forRoot(), 
    AppRoutingModule,
    SnlComponentsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true, // Allow multiple interceptors
    }, 
    provideHttpClient(withInterceptorsFromDi()),
    AuthGuard,
    DatePipe, 
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

