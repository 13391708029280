import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from '../app/auth/_guards/auth.guard';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';


const routes: Routes = [
  { path: 'auth', component: AuthComponent,data :{sidebar:false} },
  { path: 'page/privacy-policy', component: PrivacyPolicyComponent,data :{sidebar:false, title:"Privacy policy view",width:'full'}  },
  { 
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', loadChildren: () => import('../app/pages/home/home.module').then( m => m.HomePageModule) },
      { path: 'profile', loadChildren: () => import('../app/pages/profile/profile.module').then( m => m.ProfileModule) },
      { path: 'documents', loadChildren: () => import('../app/pages/documents/documents.module').then( m => m.DocumentsModule) },
      { path: 'payroll-expenses', loadChildren: () => import('../app/pages/payroll-expenses/payroll-expenses.module').then( m => m.PayrollExpensesModule) },
      { path: 'activities', loadChildren: () => import('../app/pages/activity/activity.module').then( m => m.ActivityModule) },
      { path: 'privacy-policy', component: PrivacyPolicyComponent,data :{sidebar:true, title:"Privacy policy"}  },

    ],
    data :{sidebar:true} 
  }
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
