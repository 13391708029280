<div class="theme-container privacy-policy">
  <div class="theme-container-inner" [ngClass]="{ 'full-width-body': isPublic }">
    <div *ngIf="(isPublic && isDesktop); else privateHeader"
      class="gap-24 public-header d-flex align-items-center header breadcrumb-main">
      <img alt="ST-logo" src="../assets/img/ST-logo.svg" />
      <span class="color__primary">|</span>
      <h1 class="header-h1  fw-700 color__primary">{{ title }}</h1>
    </div>
    <ng-template #privateHeader>
      <div *ngIf="isDesktop; else mobilePublicHeader" class="breadcrumb-main">
        <h1 class="header-h1 fw-700 color__primary">Privacy policy</h1>
      </div>
      <ng-template #mobilePublicHeader>
        <ion-header *ngIf="(isPublic && !isDesktop)" class="navigation-header">
          <ion-toolbar class="mobile-only-toolbar">
            <ion-title class="text__center">
              <span class="sub-heading-s1 color__primary logo"> <img alt="ST-logo" src="../assets/img/ST-logo.svg" />
              </span>
            </ion-title>
          </ion-toolbar>
        </ion-header>
        <div *ngIf="(isPublic && !isDesktop)" class="d-flex align-items-center header public-header">
          <h1 class="header-h1 fw-700 color__primary">{{ title }}</h1>
        </div>
      </ng-template>
    </ng-template>
    <div class="tab-page card-mrgn-b-32 d-flex-column gap-24"
      [ngClass]="{ 'border__radius8': !isPublic , 'remove-border' : isPublic && !isDesktop}">
      <div *ngIf="!isPublic">
        <div *ngIf="isDesktop; else mobileHeader" class="d-flex align-items-center header">
          <img *ngIf="isPublic" alt="ST-logo" src="../assets/img/ST-logo.svg" />
          <span *ngIf="isPublic" class="color__primary">&nbsp;|&nbsp;</span>
          <div class="sub-heading-s1 1color__primary">{{ title }}</div>
        </div>
      </div>
      <ng-template #mobileHeader>
        <div class="d-flex align-items-center header">
          <div class="header-h1 fw-700 color__primary">{{ title }}dsfds</div>
        </div>
      </ng-template>
      <section *ngFor="let section of policySections">
        <div class="section-title color__primary title">{{ section.title }}</div>
        <p class="body-b3 color__primary title" [innerHTML]="section.content"></p>
      </section>
      <p class="body-b3 color__primary title">
        If you have questions regarding the Privacy Notice referred to above or our processing of your personal data,
        please speak to your line manager or contact us at
        <a class="color__primary_default fw-700"
          href="mailto:privacyoffice&#64;stolt.com">privacyoffice&#64;stolt.com</a>.
      </p>
    </div>
  </div>
</div>