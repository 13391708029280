import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent {

  @Input() list: any[] = [];
  @Input() pageTitle: string = '';

  constructor( private router: Router, private location: Location) { }
  selectedItem(event:any){
    if(event.isClickable){
      this.router.navigate([event.path]);
    }
  }

  goBack() {
    this.location.back();
  }

}
