<div class="inp" [ngClass]="{ 'inp__error':isError && inputType }">
    <span class="inp__container">
       <span class="inp__suffix--wrapper-left"></span>
       <textarea  (input)="input($event)" class="inp__container--textarea body-b2 upperinput" >{{value}}</textarea>
       <label for="rejectionReason" class="inp__container--label body-b1"  [ngClass]="[textAreaValue ? 'inp__container--label-float' : '' , isError && inputType ? 'inp__container--label-error' : '']">{{label}}</label>
    </span>
    <span class="inp__suffix--wrapper">
      <span class="inp__suffix--text body-b1" ></span>
    </span>
 </div>
 <div class="caption inp__helperText" *ngIf="maxLength" [ngClass]="{'inp__helperText--error':isError && inputType}">
   <ng-content *ngIf="isError && inputType">
      <span *ngIf="textAreaValue.length > maxLength">You have reached the maximum characters limit for this field.</span>
   </ng-content>
   {{textAreaValue.length}}/{{maxLength}} characters
</div>
