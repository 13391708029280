import { Component} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CrewSelfService } from '../../services/crew-self.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-comman-confirmantion-modal',
  templateUrl: './comman-confirmantion-modal.component.html',
  styleUrls: ['./comman-confirmantion-modal.component.scss'],
})
export class CommanConfirmantionModalComponent  {
  title:string = '';
  message:string = '';
  btnOneLabel:string = 'Proceed';
  btnTwoLabel:string= 'Cancel';
  proceed:boolean = true;
  submit:boolean = false;
  public subscription: Subscription;


  constructor(private readonly modalCtrl: ModalController,private readonly crewSelfService: CrewSelfService,) {
    this.subscription = this.crewSelfService.modalSuccessGet().subscribe((event) => {
      if(event.dismiss && event.title == this.title){
        this.submit = false;
        this.dismissModal('success');
      }
    })
  }

  async dismissModal(event:string) {
    this.modalCtrl.dismiss(event);
  }

  success(){
    this.submit = true;
    if(this.proceed){
      this.dismissModal('success');
    }
    else{
      this.crewSelfService.modalSuccessSet(this.title, true);
    }
    
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

}
