import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-search-dropdown-input',
  templateUrl: './search-dropdown-input.component.html',
  styleUrls: ['./search-dropdown-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchDropdownInputComponent  {
  @ViewChild('menu') menu!: ElementRef;
  public searchDropdown!: FormGroup;
  dropdownOpen: boolean = false;
  @Input() options: any;
  @Input() isError: boolean = false;
  @Output() selectedValues = new EventEmitter();
  @Output() resetDropdown = new EventEmitter();
  @Input() preSelectedItems: any;
  selectedOption: string = '';
  searchFilter:boolean = false;
  prevValue:any;
  searchText: string = '';
  constructor(private readonly renderer: Renderer2,private readonly fb: FormBuilder) {
    this.createSearchDropdownForm();
    this.renderer.listen('window', 'click',(e:Event)=>{
     if(!this.menu.nativeElement.contains(e.target)){
        this.searchText = '';
        this.dropdownOpen = false;
     }
   });
  }

  ngOnChanges(changes: SimpleChanges) {
      
      for (let change in changes) {
        this.prevValue = changes['preSelectedItems']?.previousValue;
        if(change == "options" && changes['options']?.currentValue){
          this.options = changes['options'].currentValue;
        }
        if(change == "preSelectedItems" && changes['preSelectedItems']?.currentValue){
          this.prevValue = changes['preSelectedItems']?.currentValue;
          this.preSelectedItems = changes['preSelectedItems'].currentValue;
          this.searchDropdown.patchValue({search:this.preSelectedItems});
        }
      } 
  }

  createSearchDropdownForm() {
    this.searchDropdown = this.fb.group({
      search: [''],
    });
  }

  onInputClick(event: Event){
    this.dropdownOpen = true;
  }

  onClearField(event: Event): void {
    this.searchText = '';
    this.searchDropdown.patchValue({search:''});
    this.resetDropdown.emit([]);
  }

  onSearch(event: Event): void {
    this.searchFilter = true;
    const input = event.target as HTMLInputElement;
    this.searchText = input.value;
    this.searchDropdown.patchValue({search:''});
  }

  selectItem(){
    this.searchText = '';
    this.dropdownOpen = false;
  }

  filterOptionData(){
    return  this.options.filter((item: any) => {
        return (this.searchText.length >= 3) ? item?.DESCRIPTION?.toLowerCase().includes(this.searchText?.toLowerCase()) : this.options;
    });
  }

  selectAttribute(obj:any){
    this.searchDropdown.patchValue({search:obj.DESCRIPTION});
    this.searchText = '';
    this.searchFilter = false;
    this.dropdownOpen = false;
    this.selectedValues.emit(obj);
  }

}
