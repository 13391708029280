<div class="search-input-dropdown menu" [ngClass]="{ 'error':isError && prevValue !== undefined && searchText.length == 0 || searchFilter && searchText.length == 0 }" #menu>
  <form autocomplete="off"  [formGroup]="searchDropdown">
  <div class="input">
    <snl-input
       (click) = "onInputClick($event)"
      class="upperinput"
      [label]="'Bank name'"
      [type]="'search-input'"
      [isTrailingIconDisplayed]="true" 
      (onClearField)="onClearField($event)"  
      (input)="onSearch($event)"
      [value]="searchDropdown.value.search"
      formControlName="search"
      [isError]="isError && prevValue !== undefined && searchText.length == 0 || searchFilter && searchText.length == 0"
  ></snl-input>
  </div>
  <div class="search-box" [ngClass]="{'dropdown--open':dropdownOpen}">
    <div class="dropdown--open-content scroll">
      <ng-container *ngIf="filterOptionData().length > 0 ; else noData">
        <div class="menu-item body-b1 no-hover all-option"  *ngFor="let item of filterOptionData()" (click)="selectAttribute(item)">
          <span class="body-b1 color__primary" [innerHTML]="item.DESCRIPTION | bold:searchText"></span>
        </div>
     </ng-container>
     <ng-template #noData>
      <div class="menu-item body-b1 no-hover">
        <span class="body-b1 color__primary">No items found</span>
      </div>
     </ng-template> 
    </div>
  </div>
 </form> 
   
</div>
