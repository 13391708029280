import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-content-modal',
  templateUrl: './content-modal.component.html',
  styleUrls: ['./content-modal.component.scss'],
})
export class ContentModalComponent {
  @Input() options: any;
  @Input() label: string = '';
  @Input() buttonPrimary = '';
  @Input() buttonSecondary = '';
  @Input() preSelectedItems: any;
  isDesktop: boolean = false;
  filterText:string = '';
  selectedValue:any
  constructor(private readonly modalCtrl: ModalController) { }

 
  onSearch(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.filterText = input.value;
  }

  filterOptionData(){
   return  this.options.filter((item: any) => {
      return item?.name?.toLowerCase().includes(this.filterText?.toLowerCase());
    });
  }

  onClearField(event: Event): void {
    this.onSearch({target:{value:''}} as any)
  }

  dismissModal(event: string) {
    this.modalCtrl.dismiss(event);
  }

  selectAttribute(value:any)
  {
    if(this.preSelectedItems != value.id){
      this.preSelectedItems = value.id;
      this.selectedValue = [value];
    }
  }

  resetAttribute()
  {
    this.selectedValue = [''];
    this.apply('clear');
  }

  apply(value:any){
    let dismissData = {
      event: value,
      selectedValue: JSON.parse(JSON.stringify(this.selectedValue)),
    }
    this.modalCtrl.dismiss(dismissData);
  }

  disabled(){
    return this.selectedValue ? false : true;
  }


}
