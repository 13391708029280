import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from '../../shared/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(public authService:AuthService) {}

    canActivate(): Observable<boolean> | boolean {
       return this.authService.authGuard();
    }
}