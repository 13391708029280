<div class="navigation__panel-container" [ngClass]="{ 'collapsed':isCollapsed }">
  <div class="navigation-header" [ngClass]="[navType == 'dev' ? 'dev' : '' || navType == 'uat' ? 'uat' : '' ]">
    <img [ngClass]="{ 'hide':!isCollapsed}"  alt="ST-logo-small" src="../assets/img/ST-logo-small.svg" />
    <img [ngClass]="{ 'hide':isCollapsed}"  alt="ST-logo" src="../assets/img/ST-logo.svg" />
  </div>
  <div class="navigation__panel scroll"  [ngClass]="[(device == 'ios' && isNative)? 'ios-device' : '' || (device == 'android') ? 'android-device' : 'web']">
    <div class="navigation__top">
      <div class="navigation__options" *ngFor="let menuItem of nevigationTopMenu" [routerLink]="menuItem.path">
        <div class="navigation__panel-content" [ngClass]="{ 'navigation__panel-content--selected':menuItem.selected}" >
            <div class="navigation__panel-menu-list" [ngClass]="{ 'navigation__panel-menu-list--expanded':!isCollapsed }">
              <span class="navigation__panel-icon df-c">
                <snl-icon [ngClass]="{ 'hide':menuItem.selected}" [iconType]="menuItem.iconType" [icon]="menuItem.iconName"></snl-icon>
                <snl-icon [ngClass]="{ 'hide':!menuItem.selected}" [iconType]="menuItem.iconType" icon="{{menuItem.iconName}}-active"></snl-icon>
              </span>
              <span class="navigation__option-top body-b1">{{menuItem.displayName}}</span>
            </div>
            <div [ngClass]="{'navigation__panel-content--selected-border':menuItem.selected }"></div>
        </div>
      </div>
    </div>
    <div class="navigation__bottom">
      <div class="navigation__options"   *ngFor="let menuItem of nevigationBottomMenu" [routerLink]="menuItem.path" [ngClass]="{'notifications':menuItem.path == 'notifications'}">
        <div class="navigation__panel-content" [ngClass]="{ 'navigation__panel-content--selected':menuItem.selected}">
            <div class="navigation__panel-menu-list" [ngClass]="{ 'navigation__panel-menu-list--expanded':!isCollapsed }">
              <span class="navigation__panel-icon df-c">
                <snl-icon [ngClass]="{ 'hide':menuItem.selected}" [iconType]="menuItem.iconType" [icon]="menuItem.iconName"></snl-icon>
                <snl-icon [ngClass]="{ 'hide':!menuItem.selected}" [iconType]="menuItem.iconType" icon="{{menuItem.iconName}}-active"></snl-icon>
              </span>
              <span class="navigation__option-top body-b1">{{menuItem.displayName}}</span>
            </div>
            <div [ngClass]="{'navigation__panel-content--selected-border':menuItem.selected }"></div>
        </div>
      </div>
      <div [ngClass]="{'navigation__option-profile': !isCollapsed, 'df-c navigation__profile-icon': isCollapsed}">
        <span class="profile__image--small">
          <img *ngIf="!isSkelton"  alt="prfile" [src]="loginUserDetail.profileImage" />
          <ion-thumbnail *ngIf="isSkelton"  slot="start" class="profile"><ion-skeleton-text animated="true" ></ion-skeleton-text></ion-thumbnail>
        </span>
        <div  *ngIf="!isCollapsed">
          <ion-skeleton-text *ngIf="isSkelton"  animated="true" class="name"></ion-skeleton-text>
          <ion-skeleton-text *ngIf="isSkelton"   animated="true" class="designation"></ion-skeleton-text>
          <div class="navigation__name section-title" *ngIf="!isSkelton">{{loginUserDetail.login_user_name}}</div>
          <div class="navigation__designation caption" *ngIf="!isSkelton">{{loginUserDetail.designation}}</div>
        </div>
      </div>
      <div class="navigation__options navigation__options_signOut" (click)="signOut()">
        <div class="navigation__panel-content">
            <div class="navigation__panel-menu-list" [ngClass]="{ 'navigation__panel-menu-list--expanded':!isCollapsed }">
                <span class="navigation__panel-icon df-c"><snl-icon iconType="svg" icon="icon/log-out"></snl-icon></span>
                <span class="navigation__option-top body-b1">Sign Out</span>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pannel__bottom">
    <div class="navigation__separator-horizontal"></div>
    <div class="navigation__collapse" (click)="navigationCollapse()">
      <snl-icon [ngClass]="{ 'hide':isCollapsed}"   iconType="svg" icon="icon/collapse"></snl-icon>
      <snl-icon [ngClass]="{ 'hide':!isCollapsed}"  iconType="svg" icon="icon/expand"></snl-icon>
    </div>
  </div>
</div>