<ion-header class="navigation-header">
  <ion-toolbar class="mobile-only-toolbar">
    <ion-buttons slot="start" class="df-c" (click)="openMenu()">
      <snl-icon iconType="svg" icon="icon/menu"></snl-icon>
    </ion-buttons>
    <ion-title class="text__center">
      <span class="sub-heading-s1 color__primary">{{title}}</span>
    </ion-title>
    <ion-buttons slot="end" class="df-c">
      <ion-button routerLink="home">
        <snl-icon iconType="svg" icon="icon/notification"></snl-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>