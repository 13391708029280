export const environment = {
  PRODUCTION: false,
  NAV_TYPE: 'dev',  
  INTRANSIT_ENCRYPTION_ENABLED: false,

  CREW_APP_BACKEND_API_BASE_URL: 'https://api.stoltdev.com/css-crew-apis/v1',
  SIGNALR_WRAPPER_API_BASE_URL: 'https://api.stoltdev.com/signalr-wrapper-apis',
  CSS_FUNC_APP_PUBLIC_API_BASE_URL: 'https://api.stoltdev.com/css-fa-public-apis',
  CSS_FUNC_APP_PRIVATE_API_BASE_URL: 'https://api.stoltdev.com/css-fa-private-apis',
  
  B2C_CLIENT_ID: '042c4399-bcf8-4a76-9035-a76c3faa7f6c',
  B2C_REDIRECT_URI: 'https://css.stoltdev.com/auth',
  B2C_LOGOUT_REDIRECT_URI: 'https://css.stoltdev.com/auth?logout=true',
  B2C_LOGIN_AUTHORITY:'https://stoltdevb2c.b2clogin.com/stoltdevb2c.onmicrosoft.com/B2C_1A_STCSS_SIGNUP_SIGNIN',
  B2C_USER_LOGIN_SCOPE: 'openid offline_access',
  B2C_USER_TOKEN_SCOPE: 'https://stoltdevb2c.onmicrosoft.com/2ff50c1b-a2cb-4404-9787-f6914d267eb9/read',
};
 